/* HomepageSlider.css */

.homepage-slider {
    width: 100%;
    margin: 0 auto;
    position: relative;
  }
  
  .slider-item {
    position: relative;
  }
  
  .slider-item img {
    width: 100%;
    height: 500px;
  }

  .slider-content1 {
    text-align: center;

  }
  
  .slider-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: #fff; /* Adjust text color as needed */
  }
  
  .slider-content h2 {
    font-size: 2.5rem; /* Adjust heading font size as needed */
    margin-bottom: 20px;
  }
  
  .slider-content p {
    font-size: 1.2rem; /* Adjust paragraph font size as needed */
    max-width: 600px;
    margin: 0 auto;
  }
  
  /* Additional styling for slick carousel dots (optional) */
  .slick-dots {
    position: absolute;
    bottom: 20px;
    width: 100%;
    text-align: center;
    margin: 0;
    padding: 0;
  }
  
  .slick-dots li {
    display: inline-block;
    margin: 0 5px;
    padding: 0;
  }
  
  .slick-dots button {
    font-size: 10px; /* Adjust dot size as needed */
    border-radius: 50%;
    width: 10px;
    height: 10px;
    background-color: #fff; /* Adjust dot color as needed */
    border: 1px solid #fff; /* Adjust dot border color as needed */
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .slick-dots button:hover {
    background-color: #333; /* Adjust dot hover color as needed */
  }
  