.section2{
margin-right: 20px;
}

.section2,.section1 {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.section2 {
    /* height:85%; */
    overflow-y: scroll;
  } 
  
  h5{
    font-size:20px;
    font-weight:bold;
    font-family:cursive;
    color: brown;
  }
  #headingAbove{
    display:none;
  }
  
  .section2::-webkit-scrollbar
  {
    width: 10px;
    background-color:brown;
  
  }
  
   .App {
     text-align: center;
   }
  
   .my-item {
     background-color: white !important;
     color:black;
   }
  
   .selected-item {
     background-color: brown !important;
   }
  
  
   /* body {
     background-color: bisque;
     font-family: cursive;
     background-color: black;
     color:white;
     line-height: 1.6;
     margin: 0;
     padding: 0;
     background-image: url('/public/images/img-10.jpg'); 
     background-size: cover;
     background-repeat: no-repeat;
     background-position: center center;
    } */
  
   .container2 {
     width: 100vw;
     display: grid;
     grid-template-columns: 2fr 1fr;
     grid-template-rows: .7fr;
     text-transform: capitalize; 
     gap:1rem;
     padding-top: 2rem;
     padding-left: 1rem;
     height:700px;
   }


   
   @media screen and (max-width: 480px) {
     .container2 {
       grid-template-columns: 1fr;
       grid-template-rows: .6fr 1.4fr;
       padding-top: 0;
       padding-left: 0;
     }
  
     .section2 {
         height:50%; 
        overflow-y: scroll;
      
     }

     .section1 {
      padding-top: 10px !important;
      padding-bottom: 70px !important;
    }
     
     h5{
      font-size:15px;
      font-weight:500;
      font-family:cursive;
      color: brown;
    }
  
    #headingBelow{
      display:none;
    }
  
    #headingAbove{
      display:block;
    }
   }
  
   @media screen and (min-width: 481px) and (max-width: 1024px) {
     .container2 {
       grid-template-columns: 1fr;
       grid-template-rows: 1fr 1fr;
       padding-top: 0;
       padding-left: 0;
     }
  
     .section2 {
      /*  height:50%; */
      overflow-y: scroll;
     }

     
    
  
     h5{
      font-size:20px;
      font-weight:500;
      font-family:cursive;
      color: brown;
    }
    #headingBelow{
      display:none;
    }
    #headingAbove{
      display:block;
    }
  
    
   }