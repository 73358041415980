
    .body {
      line-height: 1.6;
      margin: 0;
      padding: 0;
      background-image: url('/public/images/background-image3.jpg'); 
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center center;
    }

    header {
      background-color: #333;
      color: #fff;
      padding: 20px;
      text-align: center;
    }

    

    .container1 {
      max-width: 800px;
      margin: 0 auto;
      padding: 40px 20px;
      position: relative;
      background-color: transparent;
    }

    .card {
      background-color:white;
      color:#333;
      border-radius: 5px;
      padding: 20px;
      margin-bottom: 20px;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
      /* font-family: cursive; */
    }

    .card-title {
      font-size: 24px;
      margin-top: 0;
    }

    .card-description {
      margin-top: 10px;
    }

    .image-container {
      position: absolute;
      top: 20px;
      right: 20px;
      max-width: 300px;
    }

    .image-container img {
      max-width: 100%;
      height: auto;
    }
 
